/*
Property Image Gallery
Fixed layout
CP
*/
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.on-page-editor .gallery-inner img {
  max-width: 100%;
}

.property-image-gallery .image-gallery__container {
  display: flex;
  text-align: center;
  font-size: 2em;
  color: white;
  flex-wrap: wrap;
}

.property-image-gallery .image-gallery__container .image-gallery__inner {
  padding: 26% 12%;
}

.property-image-gallery .image-gallery__container .image-gallery__inner .btn.btn-link {
  font-size: 0.8em;
  margin-top: 0.8em;
}

.property-image-gallery .image-gallery__container .image-gallery__inner .btn.btn-link:hover {
  color: #fff;
}

@media (max-width: 767px) {
  .property-image-gallery .image-gallery__container .image-gallery__inner {
    padding-top: 3em;
    padding-bottom: 3em;
  }
  .property-image-gallery .image-gallery__container .image-gallery__inner p {
    font-size: 0.8em;
  }
}

.property-image-gallery .image-gallery__container p.image-gallery__header {
  font-size: 1.6em;
  font-family: "Frank Ruhl Libre", serif;
  line-height: 1.2em;
  padding-bottom: 1.5em;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper {
  margin: 0.5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(1) {
  flex-basis: 44%;
  min-width: 180px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(2) {
  flex-basis: 22%;
  min-width: 180px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(3) {
  border-width: 1px 1px 0 1px;
  flex-basis: 33%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(4) {
  border-width: 1px 1px 0 0px;
  flex-basis: 33%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(5) {
  flex-basis: 22%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(6) {
  flex-basis: 44%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(7) {
  flex-basis: 33%;
  min-width: 180px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(8) {
  flex-basis: 33%;
  min-width: 180px;
  flex-grow: 1;
}

.property-image-gallery .image-gallery__container .image-gallery__cell-wrapper:nth-child(9) {
  flex-basis: 33%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__inner {
  padding: 7% 24% 6%;
  min-height: 260px;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(1) {
  flex-basis: 58%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(2) {
  flex-basis: 40%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(3) {
  flex-basis: 40%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(4) {
  flex-basis: 58%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(5) {
  flex-basis: 58%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(6) {
  flex-basis: 40%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(7), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(10), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(12), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(13), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(16), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(17) {
  flex-basis: 40%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(8), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(9), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(11), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(14), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(15), .property-image-gallery.image-gallery-2-column .image-gallery__cell-wrapper:nth-child(18) {
  flex-basis: 58%;
  min-width: 300px;
  flex-grow: 1;
}

.property-image-gallery a.btn {
  color: #fff;
}

.property-image-gallery a.btn:after {
  background-color: #fff;
}

.on-page-editor .image-gallery__container .edit-here-hint {
  color: #000;
}
